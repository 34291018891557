import { createWebHistory, createRouter } from "vue-router";
import store from '../store/index.js';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: () => import('../pages/HomePage/HomePage.vue')
    },
    {
      path: '/hotels',
      name: 'HotelsPage',
      component: () => import('../pages/HotelsPage/HotelsPage.vue'),
      meta: { authCompleted: true }
    },
    {
      path: '/cart',
      name: 'CartPage',
      component: () => import('../pages/CartPage/CartPage.vue'),
      meta: { authCompleted: true }
    },
    {
      path: '/booking',
      name: 'BookingPage',
      component: () => import('../pages/BookingPage/BookingPage.vue'),
      meta: { authCompleted: true }
    },
    {
      path: '/balance',
      name: 'BalancePage',
      component: () => import('../pages/BalancePage/BalancePage.vue'),
      meta: { authCompleted: true }
    },
    {
      path: '/hotels/:uuidHotel',
      name: 'HotelPage',
      component: () => import('../pages/HotelPage/HotelPage.vue'),
      meta: { authCompleted: true }
    },
    {
      path: '/payment/success',
      component: () => import('../pages/BalancePage/BalancePage.vue'),
      props: { success: true },
      meta: { authCompleted: true }
    },
    {
      path: '/payment/fail',
      component: () => import('../pages/BalancePage/BalancePage.vue'),
      props: { error: true},
      meta: { authCompleted: true }
    }
  ]
})

export default router;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authCompleted) && !store.state.personal.authCompleted) {
    next('')
  } else {
    next()
  }
})