export default {
  state: () => ({
    hotel: {},
    range: {}
  }),
  mutations: {
    SET_HOTEL(state, hotel) {
      state.hotel = hotel;
    },
    SET_RANGE(state, range) {
      state.range = range;
    },
  },
  actions: {
    setHotel(context, hotel) {
      context.commit('SET_HOTEL', hotel);
    },
    setRange(context, range) {
      context.commit('SET_RANGE', range);
    },
  }
};